const DEV_API_BASE_URL = 'https://bcomexapi.omexinfotech.com/pcf';
const PROD_API_BASE_URL = 'https://bc.partsconnect.co/pcf';

const defaultMethod = 'GET';
const defaultHeaders = { 'Content-Type': 'application/json; charset=utf-8' };

// local API

export function pcfApi(endpoint, { env='p', method=defaultMethod, headers=defaultHeaders, mode, body } = {}) {
  let apiBaseUrl;

  switch (env) {
    case 'd':
      apiBaseUrl = DEV_API_BASE_URL;
      break;

    case 'p': 
    default:
      apiBaseUrl = PROD_API_BASE_URL;
      break;
  }

  return api(endpoint, { apiBaseUrl, method, headers, mode, body });
}

// handling bad json response: https://github.com/github/fetch/issues/235

function api(endpoint, { apiBaseUrl, method=defaultMethod, headers=defaultHeaders, mode, body } = {}) {
  return new Promise((resolve, reject) => {
    fetch(`${apiBaseUrl}${endpoint}`, { method, headers, mode, body })
      .then(res => {
        if (res.ok) {
          return resolve(res.json());
        } else {
          reject(res); 
        }
      });
  });
}
